import { createApp } from 'vue'
import { createHead } from '@vueuse/head'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import VCalendar from 'v-calendar'
import '@/assets/tailwind.css'

const app = createApp(App)
const head = createHead()

app.use(head).use(router).use(store).use(VCalendar).mount('#app')