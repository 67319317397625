import { createRouter, createWebHashHistory } from 'vue-router'
import store from "@/store/index"

const routes = [{
        path: '/',
        name: 'home',
        component: () =>
            import ('@/views/Home.vue'),
        meta: { requireAuth: true },
    },
    {
        path: '/login/',
        name: 'login',
        component: () =>
            import ('@/views/LogIn.vue'),
        meta: { requireLogIn: true },
    },
    {
        path: '/logout/',
        name: 'logout',
        component: () =>
            import ('@/views/LogOut.vue'),
        meta: { requireAuth: true },
    },
    {
        path: '/project/',
        name: 'project-manage',
        component: () =>
            import ('@/views/ProjectManage.vue'),
        meta: { requireAuth: true },
    },
    {
        path: '/project/student/',
        name: 'project-student',
        component: () =>
            import ('@/views/StudentManage.vue'),
        meta: { requireAuth: true },
    },
    {
        path: '/project/teacher/',
        name: 'project-teacher',
        component: () =>
            import ('@/views/ProjectManage.vue'),
        meta: { requireAuth: true },
    },
    {
        path: '/project/:pid/',
        name: 'project-view',
        component: () =>
            import ('@/views/ProjectView.vue'),
        meta: { requireAuth: true },
    },
    {
        path: '/project/add/',
        name: 'project-add',
        component: () =>
            import ('@/views/ProjectEdit.vue'),
        meta: { requireAuth: true },
    },
    {
        path: '/project/:pid/edit/',
        name: 'project-edit',
        component: () =>
            import ('@/views/ProjectEdit.vue'),
        meta: { requireAuth: true },
    },
    {
        path: '/project/list/',
        name: 'project-list',
        component: () =>
            import ('@/views/ProjectList.vue'),
        meta: { requireAuth: true },
    },
    {
        path: '/form/:fid/project/:pid/',
        name: 'form-view',
        component: () =>
            import ('@/views/FormView.vue'),
        meta: { requireAuth: true },
    },
    {
        path: '/form/:fid/project/:pid/:oid/',
        name: 'form-view-multiple',
        component: () =>
            import ('@/views/FormView.vue'),
        meta: { requireAuth: true },
    },
    {
        path: '/form/:fid/project/:pid/edit/',
        name: 'form-edit',
        component: () =>
            import ('@/views/FormEdit.vue'),
        meta: { requireAuth: true },
    },
    {
        path: '/form/:fid/project/:pid/:oid/edit/',
        name: 'form-edit-multiple',
        component: () =>
            import ('@/views/FormEdit.vue'),
        meta: { requireAuth: true },
    },
    {
        path: '/course/',
        name: 'course-manage',
        component: () =>
            import ('@/views/CourseManage.vue'),
        meta: { requireAuth: true },
    },
    {
        path: '/course/add',
        name: 'course-add',
        component: () =>
            import ('@/views/CourseEdit.vue'),
        meta: { requireAuth: true },
    },
    {
        path: '/course/:cid/edit',
        name: 'course-edit',
        component: () =>
            import ('@/views/CourseEdit.vue'),
        meta: { requireAuth: true },
    },
    {
        path: '/course/:cid/student',
        name: 'student-list',
        component: () =>
            import ('@/views/StudentList.vue'),
        meta: { requireAuth: true },
    },
    {
        path: '/course/:cid/student/add',
        name: 'student-add',
        component: () =>
            import ('@/views/StudentAdd.vue'),
        meta: { requireAuth: true },
    },
    {
        path: '/announcement/add',
        name: 'announcement-add',
        component: () =>
            import ('@/views/AnnouncementEdit.vue'),
        meta: { requireAuth: true },
    },
    {
        path: '/announcement/:id/edit',
        name: 'announcement-edit',
        component: () =>
            import ('@/views/AnnouncementEdit.vue'),
        meta: { requireAuth: true },
    },
    {
        path: '/announcement/:id/',
        name: 'announcement-view',
        component: () =>
            import ('@/views/AnnouncementView.vue'),
        meta: { requireAuth: true },
    },
    {
        path: '/calendar/add',
        name: 'calendar-add',
        component: () =>
            import ('@/views/CalendarEdit.vue'),
        meta: { requireAuth: true },
    },
    {
        path: '/calendar/:id/edit',
        name: 'calendar-edit',
        component: () =>
            import ('@/views/CalendarEdit.vue'),
        meta: { requireAuth: true },
    },
    {
        path: '/calendar/:id/',
        name: 'calendar-view',
        component: () =>
            import ('@/views/CalendarView.vue'),
        meta: { requireAuth: true },
    },
    {
        path: '/document/:type_name',
        name: 'document-view',
        component: () =>
            import ('@/views/DocumentView.vue'),
        meta: { requireAuth: true },
    },
    {
        path: '/document/:type_name/edit',
        name: 'document-edit',
        component: () =>
            import ('@/views/DocumentEdit.vue'),
        meta: { requireAuth: true },
    },
    {
        path: '/exam/',
        name: 'exam',
        component: () =>
            import ('@/views/ExamManage.vue'),
        meta: { requireAuth: true },
    },
    {
        path: '/exam/course/:course_id/exam_type/:exam_type_id/type/:type/project/:project_id',
        name: 'exam-review',
        component: () =>
            import ('@/views/ExamReview.vue'),
        meta: { requireAuth: true },
    },
    {
        path: '/status/manage',
        name: 'status-manage',
        component: () =>
            import ('@/views/StatusManage.vue'),
        meta: { requireAuth: true },
    },
    {
        path: '/form/:form_name/old_project/:pid',
        name: 'old-project-form-view',
        component: () =>
            import ('@/views/OldFormView.vue'),
        meta: { requireAuth: true },
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    linkActiveClass: 'is-active',
    routes,
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requireAuth)) {
        if (!store.getters.loggedIn) {
            next({ name: 'login', query: { redirect: to.fullPath } })
        }
        if (store.getters.payload.exp < Date.now() / 1000) {
            store.dispatch('logout')
            next('/')
        }
    }
    if (to.matched.some(record => record.meta.requireLogIn)) {
        if (store.getters.loggedIn) {
            next('/')
        }
    }
    if (to.matched.some(record => record.meta.requireStaff)) {
        if (store.getters.payload.role !== 'STF') {
            next('/')
        }
    }
    next()
})

export default router