import { createStore } from 'vuex'
import axiosBase from '@/services/index'
import axios from 'axios'
import { saveAs } from 'file-saver'

export default createStore({
    state: {
        access: localStorage.getItem('access') || null,
    },
    getters: {
        headers(state) {
            return { Authorization: `Bearer ${state.access}` }
        },
        payload(state) {
            const base64Url = state.access.split('.')[1]
            const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
            const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
            }).join(''))
            const json = JSON.parse(jsonPayload)
            return json
        },
        loggedIn(state) {
            return state.access != null
        },
    },
    mutations: {
        updateToken(state, { access }) {
            localStorage.setItem('access', access)
            state.access = access
        },
        updateAccess(state, access) {
            localStorage.setItem('access', access)
            state.access = access
        },
        removeToken(state) {
            localStorage.removeItem('access')
            state.access = null
        },
    },
    actions: {
        login(context, data) {
            return new Promise((resolve, reject) => {
                axiosBase.post('api/token/obtain/', data)
                    .then(response => {
                        context.commit('updateToken', response.data)
                        resolve(true)
                    })
                    .catch(function(error) {
                        if (error.response)
                            reject(error.response.status)
                    })
            })
        },
        logout(context) {
            context.commit('removeToken')
        },
        // GET: Category
        getCategory() {
            return new Promise((resolve, reject) => {
                axiosBase.get(`api/category/`)
                    .then((response) => resolve(response.data))
                    .catch(() => reject([]))
            })
        },
        // GET: Program
        getProgram() {
            return new Promise((resolve, reject) => {
                axiosBase.get(`api/program/`)
                    .then((response) => resolve(response.data))
                    .catch(() => reject([]))
            })
        },
        // GET: Program by course id
        getProgramByCourseId(context, params) {
            return new Promise((resolve, reject) => {
                const courseId = params.courseId
                const headers = { headers: context.getters.headers }
                axiosBase.get(`api/program/course/${courseId}`, headers)
                    .then((response) => resolve(response.data))
                    .catch(() => reject([]))
            })
        },
        // CREATE: Project
        createProject(context, data) {
            return new Promise((resolve, reject) => {
                const headers = { headers: context.getters.headers }
                axiosBase.post(`api/project/`, data, headers)
                    .then((response) => resolve(response.data))
                    .catch(() => reject(null))
            })
        },
        // UPDATE: Project
        updateProject(context, data) {
            return new Promise((resolve, reject) => {
                const projectId = data['id']
                delete data['id']
                const headers = { headers: context.getters.headers }
                axiosBase.put(`api/project/${projectId}/`, data, headers)
                    .then((response) => resolve(response.data))
                    .catch(() => reject(null))
            })
        },
        // GET: All project
        getProject(context) {
            return new Promise((resolve, reject) => {
                const headers = { headers: context.getters.headers }
                axiosBase.get(`api/project/`, headers)
                    .then((response) => resolve(response.data))
                    .catch(() => reject(null))
            })
        },
        // GET: Project by project id
        getProjectById(context, projectId) {
            return new Promise((resolve, reject) => {
                const headers = { headers: context.getters.headers }
                axiosBase.get(`api/project/${projectId}/`, headers)
                    .then((response) => resolve(response.data))
                    .catch(() => reject(null))
            })
        },
        // GET: Project by user id
        getProjectByUserId(context, userId) {
            return new Promise((resolve, reject) => {
                const headers = { headers: context.getters.headers }
                axiosBase.get(`api/user/project/${userId}/list/`, headers)
                    .then((response) => resolve(response.data))
                    .catch(() => reject(null))
            })
        },
        // GET: Project by filter (List)
        getFilteredProject(context, params) {
            return new Promise((resolve, reject) => {
                const data = { headers: context.getters.headers, params: params }
                axiosBase.get(`api/project/course/`, data)
                    .then((response) => resolve(response.data))
                    .catch(() => reject(null))
            })
        },
        // GET: Project by uuid
        getProjectByUUID(context, uuid) {
            return new Promise((resolve, reject) => {
                const data = { headers: context.getters.headers }
                axiosBase.get(`api/project/uuid/${uuid}/`, data)
                    .then((response) => resolve(response.data))
                    .catch(() => reject(null))
            })
        },
        // GET: Project with member by course id
        getProjectWithMemberByCourseId(context, params) {
            return new Promise((resolve, reject) => {
                const data = { headers: context.getters.headers, params: params }
                axiosBase.get(`api/project/member/`, data)
                    .then((response) => resolve(response.data))
                    .catch(() => reject(null))
            })
        },
        // GET: Project by user id (Check if this user have project)
        checkProjectByUserId(context, userId) {
            return new Promise((resolve, reject) => {
                const headers = { headers: context.getters.headers }
                axiosBase.get(`api/user/project/${userId}/`, headers)
                    .then((response) => resolve(response.data))
                    .catch(() => reject(false))
            })
        },
        // DELETE: Project by project id
        deleteProjectById(context, projectId) {
            return new Promise((resolve, reject) => {
                const headers = { headers: context.getters.headers }
                axiosBase.delete(`api/project/${projectId}/`, headers)
                    .then(() => resolve(true))
                    .catch(() => reject(false))
            })
        },
        // GET: Filter project document
        getFilteredProjectDocument(context, params) {
            return new Promise((resolve, reject) => {
                const data = { headers: context.getters.headers, params: params }
                axiosBase.get(`api/project/document/`, data)
                    .then((response) => resolve(response.data))
                    .catch(() => reject(null))
            })
        },
        // GET: User by student code
        getUserByCode(context, data) {
            return new Promise((resolve, reject) => {
                const headers = { headers: context.getters.headers, params: data }
                axiosBase.get(`api/user/code/${data.code}/`, headers)
                    .then((response) => resolve(response.data))
                    .catch(() => reject(null))
            })
        },
        // GET: User list (Teacher)
        getTeacher(context) {
            return new Promise((resolve, reject) => {
                const headers = { headers: context.getters.headers }
                axiosBase.get(`api/user/teacher/`, headers)
                    .then((response) => resolve(response.data))
                    .catch(() => reject(null))
            })
        },
        // DELETE: User by user id (Student)
        deleteStudentByUserId(context, data) {
            return new Promise((resolve, reject) => {
                const headers = { headers: context.getters.headers, 'Content-Type': 'multipart/form-data' }
                const courseId = data.course_id
                const userId = data.user_id
                axiosBase.delete(`api/course/${courseId}/student/${userId}/`, headers)
                    .then(() => resolve(true))
                    .catch(() => reject(false))
            })
        },
        // DELETE: All user by course id (Student)
        deleteAllStudentByCourseId(context, courseId) {
            return new Promise((resolve, reject) => {
                const headers = { headers: context.getters.headers }
                axiosBase.delete(`api/course/${courseId}/student/delete/all/`, headers)
                    .then(() => resolve(true))
                    .catch(() => reject(false))
            })
        },
        // CREATE: Course
        createCourse(context, data) {
            return new Promise((resolve, reject) => {
                const headers = { headers: context.getters.headers }
                axiosBase.post(`api/course/`, data, headers)
                    .then((response) => resolve(response.data.id))
                    .catch((error) => reject(error))
            })
        },
        // UPDATE: Course
        updateCourse(context, data) {
            return new Promise((resolve, reject) => {
                const courseId = data.course.id
                const headers = { headers: context.getters.headers }
                axiosBase.put(`api/course/${courseId}/`, data, headers)
                    .then(() => resolve(true))
                    .catch(() => reject(false))
            })
        },
        // UPDATE: Student in course
        updateStudent(context, data) {
            return new Promise((resolve, reject) => {
                const headers = { headers: context.getters.headers, 'Content-Type': 'multipart/form-data' }
                let formData = new FormData()
                formData.append('file', data['file'])
                const courseId = data.id
                axiosBase.put(`api/course/${courseId}/student/`, formData, headers)
                    .then(() => resolve(true))
                    .catch((error) => reject(error))
            })
        },
        // GET: Student by course id
        getStudentByCourseId(context, courseId) {
            return new Promise((resolve, reject) => {
                const headers = { headers: context.getters.headers }
                axiosBase.get(`api/course/${courseId}/enroll`, headers)
                    .then((response) => resolve(response.data))
                    .catch(() => reject(null))
            })
        },
        // GET: All course
        getCourse(context) {
            return new Promise((resolve, reject) => {
                const headers = { headers: context.getters.headers }
                axiosBase.get(`api/course/`, headers)
                    .then((response) => resolve(response.data))
                    .catch(() => reject(null))
            })
        },
        // GET: Course by course id
        getCourseById(context, courseId) {
            return new Promise((resolve, reject) => {
                const headers = { headers: context.getters.headers }
                axiosBase.get(`api/course/${courseId}/`, headers)
                    .then((response) => resolve(response.data))
                    .catch(() => reject(null))
            })
        },
        // GET: Course by user id
        getCourseByUserId(context, userId) {
            return new Promise((resolve, reject) => {
                const headers = { headers: context.getters.headers }
                axiosBase.get(`api/user/course/${userId}/`, headers)
                    .then((response) => resolve(response.data))
                    .catch(() => reject(null))
            })
        },
        // GET: Course that not expired by user id (101 Form)
        getCourseNotExpiredByUserId(context, userId) {
            return new Promise((resolve, reject) => {
                const headers = { headers: context.getters.headers }
                axiosBase.get(`api/course/user/${userId}/`, headers)
                    .then((response) => resolve(response.data))
                    .catch(() => reject(null))
            })
        },
        // GET: Next course (PROJECT 2)
        getNextCourse(context, courseId) {
            return new Promise((resolve, reject) => {
                const headers = { headers: context.getters.headers }
                axiosBase.get(`api/course/${courseId}/next/`, headers)
                    .then((response) => resolve(response.data))
                    .catch(() => reject(null))
            })
        },
        // GET: Course that not has next course (PROJECT 1)
        getAvailableCourse(context) {
            return new Promise((resolve, reject) => {
                const headers = { headers: context.getters.headers }
                axiosBase.get(`api/course/available/`, headers)
                    .then((response) => resolve(response.data))
                    .catch(() => reject(null))
            })
        },
        // DELETE: Course by course id
        deleteCourseById(context, courseId) {
            return new Promise((resolve, reject) => {
                const headers = { headers: context.getters.headers }
                axiosBase.delete(`api/course/${courseId}/`, headers)
                    .then(() => resolve(true))
                    .catch(() => reject(false))
            })
        },
        // GET: Form by form id
        getFormById(context, formId) {
            return new Promise((resolve, reject) => {
                const headers = { headers: context.getters.headers }
                axiosBase.get(`api/form/${formId}/`, headers)
                    .then((response) => resolve(response.data))
                    .catch(() => reject(null))
            })
        },
        // GET: Form list by course id
        getFormByCourseId(context, data) {
            let courseId = data['id']
            return new Promise((resolve, reject) => {
                const headers = { headers: context.getters.headers, params: { 'type': data['type'] } }
                axiosBase.get(`api/form/course/${courseId}/`, headers)
                    .then((response) => resolve(response.data))
                    .catch(() => reject(null))
            })
        },
        // UPDATE: Form data status by form data id
        updateFormDataStatusById(context, data) {
            return new Promise((resolve, reject) => {
                const formId = data['form_id']
                const projectId = data['project_id']
                const orderId = data['order_id']
                const status = data['status']
                const headers = { headers: context.getters.headers }
                axiosBase.put(`api/data/form/${formId}/project/${projectId}/${orderId}/`, { status: status, exist: "true" }, headers)
                    .then(() => resolve(true))
                    .catch(() => reject(false))
            })
        },
        // CREATE: Form data
        createFormData(context, data) {
            return new Promise((resolve, reject) => {
                const formId = data['form_id']
                const projectId = data['project_id']
                const rawData = data.raw_data
                const headers = { headers: context.getters.headers, 'Content-Type': 'multipart/form-data' }
                let formData = new FormData()
                for (let data in rawData)
                    formData.append(data, rawData[data])
                formData.append('exist', data.exist)
                axiosBase.post(`api/data/form/${formId}/project/${projectId}/`, formData, headers)
                    .then(() => resolve(true))
                    .catch(() => reject(false))
            })
        },
        // UPDATE: Form data
        updateFormData(context, data) {
            return new Promise((resolve, reject) => {
                const projectId = data['project_id']
                const formId = data['form_id']
                const orderId = data['order_id']
                const rawData = data.raw_data
                const headers = { headers: context.getters.headers, 'Content-Type': 'multipart/form-data' }
                let formData = new FormData()
                for (let data in rawData) {
                    formData.append(data, rawData[data])
                }
                formData.append('exist', data.exist)
                axiosBase.put(`api/data/form/${formId}/project/${projectId}/${orderId}/`, formData, headers)
                    .then(() => resolve(true))
                    .catch(() => reject(false))
            })
        },
        // UPDATE: File form data
        updateFileFormData(context, data) {
            return new Promise((resolve, reject) => {
                const projectId = data.project_id
                const formId = data.form_id
                const orderId = data.order_id
                const file = data.file
                const exist = data.exist
                const headers = { headers: context.getters.headers, 'Content-Type': 'multipart/form-data' }
                let formData = new FormData()
                formData.append('upload', file)
                formData.append('exist', exist)
                axiosBase.put(`api/data/form/${formId}/project/${projectId}/${orderId}/`, formData, headers)
                    .then(() => resolve(true))
                    .catch(() => reject(false))
            })
        },
        // GET: Form data by params
        getFormData(context, data) {
            return new Promise((resolve, reject) => {
                const formId = data['form_id']
                const projectId = data['project_id']
                const orderId = data['order_id']
                const headers = { headers: context.getters.headers }
                axiosBase.get(`api/data/form/${formId}/project/${projectId}/${orderId}/`, headers)
                    .then((response) => resolve(response.data))
                    .catch(() => reject(false))
            })
        },
        // GET: Form template
        getFormTemplate(context) {
            return new Promise((resolve, reject) => {
                const headers = { headers: context.getters.headers }
                axiosBase.get(`api/template/`, headers)
                    .then((response) => resolve(response.data))
                    .catch(() => reject([]))
            })
        },
        // CREATE: Announcement
        createAnnouncement(context, data) {
            return new Promise((resolve, reject) => {
                const headers = { headers: context.getters.headers, 'Content-Type': 'multipart/form-data' }
                let formData = new FormData()
                for (let dat in data) {
                    if (dat == 'file' && data[dat] !== undefined) {
                        formData.append('file_length', data[dat].length)
                        for (let count = 0; count < data[dat].length; count++) {
                            formData.append('file' + count, data[dat][count])
                        }
                        continue
                    } else if (dat == 'file' && data[dat] == undefined) {
                        formData.append('file_length', 0)
                    }
                    formData.append(dat, data[dat])
                }
                axiosBase.post(`api/announcement/`, formData, headers)
                    .then((response) => resolve(response.data))
                    .catch(() => reject(false))
            })
        },
        // UPDATE: Announcement
        updateAnnouncement(context, data) {
            return new Promise((resolve, reject) => {
                const headers = { headers: context.getters.headers, 'Content-Type': 'multipart/form-data' }
                let formData = new FormData()
                for (let dat in data) {
                    if (dat == 'file' && data[dat] !== undefined) {
                        formData.append('file_length', data[dat].length)
                        for (let count = 0; count < data[dat].length; count++) {
                            formData.append('file' + count, data[dat][count])
                        }
                        continue
                    } else if (dat == 'file' && data[dat] == undefined) {
                        formData.append('file_length', 0)
                    }
                    formData.append(dat, data[dat])
                }
                const announcementId = data.id
                axiosBase.put(`api/announcement/${announcementId}/`, formData, headers)
                    .then(() => resolve(true))
                    .catch(() => reject(false))
            })
        },
        // GET: All announcement
        getAnnouncement(context) {
            return new Promise((resolve, reject) => {
                const headers = { headers: context.getters.headers }
                axiosBase.get(`api/announcement/`, headers)
                    .then((response) => resolve(response.data))
                    .catch(() => reject([]))
            })
        },
        // GET: Announcement by announcement id
        getAnnouncementById(context, announcementId) {
            return new Promise((resolve, reject) => {
                const headers = { headers: context.getters.headers }
                axiosBase.get(`api/announcement/${announcementId}/`, headers)
                    .then((response) => resolve(response.data))
                    .catch(() => reject(null))
            })
        },
        // DELETE: Announcement by announcement id
        deleteAnnouncementById(context, announcementId) {
            return new Promise((resolve, reject) => {
                const headers = { headers: context.getters.headers }
                axiosBase.delete(`api/announcement/${announcementId}/`, headers)
                    .then(() => resolve(true))
                    .catch(() => reject(false))
            })
        },
        // CREATE: Document
        createDocument(context, data) {
            return new Promise((resolve, reject) => {
                const headers = { headers: context.getters.headers, 'Content-Type': 'multipart/form-data' }
                let formData = new FormData()
                for (let dat in data) {
                    if (dat == 'file' && data[dat] !== undefined) {
                        formData.append('file_length', data[dat].length)
                        for (let count = 0; count < data[dat].length; count++) {
                            formData.append('file' + count, data[dat][count])
                        }
                        continue
                    } else if (dat == 'file' && data[dat] == undefined) {
                        formData.append('file_length', 0)
                    }
                    formData.append(dat, data[dat])
                }
                axiosBase.post(`api/document/`, formData, headers)
                    .then(() => resolve(true))
                    .catch(() => reject(false))
            })
        },
        // GET: Document
        getDocument(context) {
            return new Promise((resolve, reject) => {
                const headers = { headers: context.getters.headers, 'Content-Type': 'multipart/form-data' }
                axiosBase.get(`api/document/`, headers)
                    .then((response) => resolve(response.data))
                    .catch(() => reject(null))
            })
        },
        // GET: Form data file
        downloadFormDataFile(context, data) {
            return axios({
                url: process.env.NODE_ENV == 'production' ? `https://carbon.it.kmitl.ac.th/api/data/form/${data['form_id']}/project/${data['project_id']}/${data['order_id']}/download` : `http://127.0.0.1:8000/api/data/form/${data['form_id']}/project/${data['project_id']}/${data['order_id']}/download`,
                method: 'GET',
                responseType: 'blob',
                headers: context.getters.headers
            }).then((response) => {
                saveAs(new Blob([response.data]), data['name'])
            })
        },
        // GET: Document file (Announcement, Document)
        downloadDocumentFile(context, data) {
            return axios({
                url: process.env.NODE_ENV == 'production' ? 'https://carbon.it.kmitl.ac.th/api/document/' + data['id'] + '/download/' : 'http://127.0.0.1:8000/api/document/' + data['id'] + '/download/',
                method: 'GET',
                responseType: 'blob',
                params: data,
                headers: context.getters.headers
            }).then((response) => {
                saveAs(new Blob([response.data]), data['name'])
            }).catch(function(error) {
                if (error.response)
                    return Promise.reject(error.response.status)
            })
        },
        // GET: Exam room by params
        getExam(context, data) {
            return new Promise((resolve, reject) => {
                const headers = { headers: context.getters.headers, params: data }
                axiosBase.get(`api/exam/`, headers)
                    .then((response) => resolve(response.data))
                    .catch(() => reject(null))
            })
        },
        // GET: Exam score by params
        getExamScore(context, data) {
            return new Promise((resolve, reject) => {
                const headers = { headers: context.getters.headers, params: data }
                axiosBase.get(`api/exam/score/`, headers)
                    .then((response) => resolve(response.data))
                    .catch(() => reject(null))
            })
        },
        // GET: Coop form by params
        getCoopForm(context, data) {
            return new Promise((resolve, reject) => {
                const headers = { headers: context.getters.headers, params: data }
                axiosBase.get(`api/exam/form/`, headers)
                    .then((response) => resolve(response.data))
                    .catch(() => reject(null))
            })
        },
        // Export exam file
        exportSign(context, data) {
            return new Promise((reject) => {
                const headers = { headers: context.getters.header, responseType: "blob" }
                let file_name = data[0].type == 'std' ? "ใบเซ็นชื่อนักศึกษาเข้าสอบ.xlsx" : "ใบเซ็นชื่ออาจารย์และกรรมการสอบ.xlsx"
                axiosBase.post(`api/exam/export/`, data, headers)
                    .then((response) => {
                        saveAs(new Blob([response.data]), file_name)
                    }).catch(() => reject(null))
            })
        },
        // Notification
        notification(context, data) {
            return new Promise((resolve, reject) => {
                const headers = { headers: context.getters.headers }
                axiosBase.post(`api/notification/`, data, headers)
                    .then((response) => resolve(response.data))
                    .catch(() => reject(false))
            })
        },
        // GET: Coop notification by project id
        getNotificationByProjectId(context, projectId) {
            return new Promise((resolve, reject) => {
                const headers = { headers: context.getters.headers }
                axiosBase.get(`api/project/${projectId}/coop_email/`, headers)
                    .then((response) => resolve(response.data))
                    .catch(() => reject(false))
            })
        },
        // GET: 101 File
        download101File(context, projectId) {
            return new Promise((reject) => {
                const headers = { headers: context.getters.headers, responseType: "blob" }
                axiosBase.get(`api/project/${projectId}/file/`, headers)
                    .then((response) => {
                        saveAs(new Blob([response.data]), 'แบบฟอร์มเสนอหัวข้อโครงงาน.pdf')
                    }).catch(() => reject(null))
            })
        }

    },
    modules: {}
})