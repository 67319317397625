<template>
  <router-view></router-view>
</template>

<script>
import { defineComponent, computed, reactive } from 'vue'
import { useHead } from '@vueuse/head'

export default defineComponent({
  setup() {
    const siteData = reactive({
      title: 'Carbon@ITKMITL',
      description: 'ระบบบริหารจัดการวิชาโครงงาน สหกิจศึกษา และศึกษาอิสระของคณะเทคโนโลยีสารสนเทศ สถาบันเทคโนโลยีพระจอมเกล้าเจ้าคุณทหารลาดกระบัง',
    })

  useHead({
    title: computed(() => siteData.title),
    meta: [
      {
        name: 'title',
        content: computed(() => siteData.title),
      },
      {
        name: 'description',
        content: computed(() => siteData.description),
      },
      { property: 'og:title',
        content: computed(() => siteData.title)
      },
      {
        property: 'og:description',
        content: computed(() => siteData.description)
      }
    ],
  })
  },
})
</script>

<style>
#app {
  font-family: 'Sarabun';
  src: url('~@/assets/fonts/Sarabun.ttf') format("truetype"); 
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
@font-face {
  font-family: 'Sarabun';
  src: url('~@/assets/fonts/Sarabun.ttf') format("truetype"); 
}
</style>
